import React, {Fragment, memo, useState} from "react";
import {Col, Row, Table} from "antd";
import {CkButton, CkInput} from "../../../../CkUI";
import AdditionalContactForm from "./AdditionalContactForm";
import {useDispatch, useSelector} from "react-redux";
import {updateCustomerFormData} from "../../../../stores/Slice/NewCustomerFormSlice";
import {getCustomerFormData} from "../../../../stores";

interface IProps {
  additionalContacts: any[];
}

const ClientDataForm: React.FC<IProps> = ({ additionalContacts }: IProps) => {
  const customerData = useSelector(getCustomerFormData);
  const dispatch = useDispatch();
  const [showAdditionalContactForm, setShowAdditionalContactForm] = useState(false);

  const updateClientData = (dataToUpdate) => {
    dispatch(updateCustomerFormData(dataToUpdate))
  };

  return (
    <Fragment>
      <Row justify="space-between">
        <p className="add-client-title-text">
          Datos de contacto
        </p>
        <p>
          * = Dato obligatorio
        </p>
      </Row>
      <Row>
        <Col sm={24} lg={10}>
          <CkInput
            name={["customerName"]}
            extra="*Nombre"
            rules={[
              {
                required: true,
                message: "Ingresa el nombre",
              },
              {
                pattern: new RegExp(/^[a-zA-Zá-úÁ-Ú0-9\s]+$/gi),
                message: "El campo no admite caracteres especiales",
              },
            ]}
            inputProps={{
              value: customerData.customerName,
              name: "customerName",
              placeholder: "Escribe el nombre del cliente",
              onChange: (e: any)=> {
                updateClientData({ customerName: e.target.value })
              }
            }}
            style={{ paddingRight: "20px"}}
          />
        </Col>
        <Col sm={24} lg={10}>
          <CkInput
            name={["customerLastName"]}
            extra="*Apellido"
            rules={[
              {
                required: true,
                message: "Ingresa el apellido",
              },
              {
                pattern: new RegExp(/^[a-zA-Zá-úÁ-Ú0-9\s]+$/gi),
                message: "El campo no admite caracteres especiales",
              },
            ]}
            inputProps={{
              value: customerData.customerLastName,
              name: "customerLastName",
              placeholder: "Escribe el apellido del cliente",
              onChange: (e: any)=>{
                updateClientData({ customerLastName: e.target.value })
              }
            }}
            style={{ paddingRight: "20px"}}
          />
        </Col>
        <Col sm={24} lg={10}>
          <CkInput
            name={["customerTel"]}
            extra="*Teléfono celular"
            rules={[
              {
                required: true,
                message: "Ingresa el teléfono del cliente",
              },
              {
                pattern: new RegExp(/^\d{10}$/gi),
                message: "El teléfono debe ser de 10 números",
              },
            ]}
            inputProps={{
              value: customerData.customerTel,
              name: "customerTel",
              placeholder: "Escribe el celular del cliente",
              onChange: (e: any)=>{
                updateClientData({ customerTel: e.target.value })
              }
            }}
            style={{ paddingRight: "20px"}}
          />
        </Col>
        <Col sm={24} lg={10}>
          <CkInput
            name={["customerEmail"]}
            extra="*Correo electrónico"
            rules={[
              {
                required: true,
                message: "Ingresa el correo electrónico",
              },
              {
                type: "email",
                message: "Ingresa un correo electrónico",
              },
            ]}
            inputProps={{
              value: customerData.customerEmail,
              name: "customerEmail",
              type: "email",
              placeholder: "Escribe el correo electrónico del cliente",
              onChange: (e: any)=>{
                updateClientData({ customerEmail: e.target.value })
              }
            }}
            style={{ paddingRight: "20px"}}
          />
        </Col>
      </Row>

      {
        additionalContacts.length > 0 ? (
          <Fragment>
            <Row justify="space-between">
              <p className="add-client-title-text">
                Contacto adicional
              </p>
            </Row>
            <Table
              dataSource={additionalContacts}
              columns={[
                {
                  title: 'Nombre',
                  dataIndex: 'contactName',
                  key: 'contactName',
                },
                {
                  title: 'Apellidos',
                  dataIndex: 'contactLastName',
                  key: 'contactLastName',
                },
                {
                  title: 'Teléfono celular',
                  dataIndex: 'contactTel',
                  key: 'contactTel',
                },
                {
                  title: 'Correo electrónico',
                  dataIndex: 'contactEmail',
                  key: 'contactEmail',
                }
              ]}
            />
          </Fragment>

        ) : null
      }

      {
        showAdditionalContactForm && (
          <AdditionalContactForm
            setOpen={setShowAdditionalContactForm}
            open={showAdditionalContactForm}
            showTitle={additionalContacts.length<1}
          />
        )
      }
      {
        !showAdditionalContactForm && (
          <Row>
            <CkButton type="text" icon="circle-add" onClick={()=> setShowAdditionalContactForm(true)}>
              Agregar contacto adicional
            </CkButton>
          </Row>
        )
      }
    </Fragment>
  );
};

export default memo(ClientDataForm);
