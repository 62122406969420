import React, { Fragment, memo, useEffect, useState } from "react";
import { Col, Divider, Form, Row, Tag } from "antd";
import { CkButton, CkInput } from "../../../../../CkUI";
import {
  setCustomerTags,
  updateAdditionalContactFormData,
} from "../../../../../stores/Slice/NewCustomerFormSlice";
import { useDispatch, useSelector } from "react-redux";
import SelectableTag from "./SelectableTag";
import { getCurrentWorkshopTags } from "../../../../../stores";

interface IProps {
  tags: any[];
  setOpen: Function;
  onCreateTagClicked: Function;
  onSaveClicked: Function;
}

const AddNewTagForm: React.FC<IProps> = ({
  tags,
  setOpen,
  onCreateTagClicked,
  onSaveClicked,
}: IProps) => {
  const dispatch = useDispatch();
  const workshopTags = useSelector(getCurrentWorkshopTags);

  const [newTag, setNewTag] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);

  useEffect(() => {
    console.log("selectedTags???: ", selectedTags);
  }, [selectedTags]);

  const selectTag = (tag: any, isSelected: boolean) => {
    if (!isSelected) {
      setSelectedTags([...selectedTags, tag]);
    } else {
      const filterDeselected = selectedTags.filter(
        (t) => t.tagId !== tag.tagId
      );
      setSelectedTags(filterDeselected);
    }
  };
  const displayWSTags = () => {
    //TODO filter already selected tags (tags)
    return workshopTags.map((tag, index) => {
      return (
        <SelectableTag
          key={`selectable-tag-${index}`}
          tag={tag}
          onSelected={selectTag}
        />
      );
    });
  };

  return (
    <div
      style={{ backgroundColor: "#F9F9F9", padding: "25px", marginTop: "15px" }}
    >
      <p style={{ color: "#001450", fontSize: "20px", fontWeight: 600 }}>
        Agregar etiquetas
      </p>
      <p style={{ fontSize: "16px", color: "#404040" }}>
        Usa las etiquetas para crear categorías si es que las requieres para
        organizar a tus clientes.
      </p>
      <p style={{ color: "#404040", fontSize: "16px", fontWeight: 600 }}>
        Selecciona una etiqueta existente
      </p>
      <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        {displayWSTags()}
      </div>
      <Row>
        <Col span={11}>
          <Divider />
        </Col>
        <p style={{ margin: 0 }}>ó</p>
        <Col span={11}>
          <Divider />
        </Col>
      </Row>

      <p style={{ color: "#404040", fontSize: "16px", fontWeight: 600 }}>
        Crea una nueva etiqueta
      </p>
      <Row>
        <Col span={10}>
          <CkInput
            name={["tags"]}
            extra="*Texto en etiqueta"
            initialValue={newTag}
            inputProps={{
              name: "tags",
              value: newTag,
              placeholder: "Escribe el texto que aparecerá en la etiqueta ",
              onChange: (e: any) => {
                setNewTag(e.target.value);
              },
            }}
          />
        </Col>
        <Col span={10}>
          <CkButton
            type="primary"
            style={{
              width: "fit-content",
              marginTop: "25px",
              marginLeft: "10px",
            }}
            onClick={() => onCreateTagClicked(newTag, setNewTag)}
            disabled={newTag.length < 1}
          >
            Crear
          </CkButton>
        </Col>
      </Row>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
        }}
      >
        <CkButton
          onClick={() => {
            if (onSaveClicked) {
              onSaveClicked(selectedTags);
              setOpen(false);
            } else {
              dispatch(setCustomerTags(selectedTags));
              setOpen(false);
            }
          }}
        >
          Guardar
        </CkButton>
        <CkButton type="text" onClick={() => setOpen(false)}>
          Cancelar
        </CkButton>
      </div>
    </div>
  );
};

export default memo(AddNewTagForm);
