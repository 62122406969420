import React, { Fragment, memo, useState } from "react";
import { Row, Tag } from "antd";
import { CkButton } from "../../../../CkUI";
import NewVehicleForm from "./NewVehicleForm";

interface IProps {
  vehicles: any[];
}

const ClientVehiclesForm: React.FC<IProps> = ({ vehicles }: IProps) => {
  const [showNewVehicleForm, setShowNewVehicleForm] = useState(false);

  const displayVehicles = () => {
    return vehicles.map((v, index) => {
      return (
        <Tag
          key={index}
          closable
          onClose={()=>{}}
        >
          {`${v.endConsumerVehicleBrand} ${v.endConsumerVehicleModel} ${v.endConsumerVehicleYear}`}
        </Tag>
      )
    })
  };

  return (
    <Fragment>
      <Row>
        <p className="add-client-title-text">
          Vehículos
        </p>
      </Row>
      <Row className="add-customer-container-vehicle-tags">
        {
          displayVehicles()
        }

      </Row>
      {
        !showNewVehicleForm && (
          <Row style={{ marginTop: "15px"}}>
            <CkButton type="text" icon="circle-add" onClick={() => setShowNewVehicleForm(!showNewVehicleForm)}>
              Agregar nuevo vehículo
            </CkButton>
          </Row>
        )
      }
      {
        showNewVehicleForm && (
          <NewVehicleForm open={showNewVehicleForm} setOpen={setShowNewVehicleForm}/>
        )
      }
    </Fragment>
  );
};

export default memo(ClientVehiclesForm);
