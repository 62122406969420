import React, { Fragment, memo, useState } from "react";
import {Col, Radio, RadioChangeEvent, Row} from "antd";
import {CkDatePicker, CkInput, CkSelect} from "../../../../CkUI";
import moment from "moment";
import {updateCustomerFormData} from "../../../../stores/Slice/NewCustomerFormSlice";
import {useDispatch} from "react-redux";

interface IProps {}

const availableTaxRegimens = [
  "Régimen de Asalariados (Sueldos y salarios)",
  "Régimen de Actividades Empresariales y Profesionales",
  "Servicios profesionales (Honorarios)",
  "Régimen de Arrendamiento",
  "Régimen de Incorporación Fiscal (RIF)",
  "Régimen Simplificado de Confianza (RESICO)",
  "Régimen de Ingresos por Intereses",
  "Régimen de Obtención de Premios",
  "Régimen de Dividendos",
  "Régimen de Demás Ingresos",
  "Régimen de Ingresos por Dividendos y Ganancias Distribuidas por Personas Morales",
  "Prestación de Servicios a través de Internet, mediante Plataformas Tecnológicas, Aplicaciones Informáticas y Similares.",
  "Régimen de Enajenación o Adquisición de Bienes",
  "Régimen General de Ley",
  "Régimen Opcional para Grupos de Sociedades",
  "Régimen Simplificado de Confianza de Personas Morales",
  "Régimen Simplificado de Confianza de Personas Morales",
  "Régimen de Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras",
  "Régimen de las Personas Morales con Fines no Lucrativos",
  "Régimen de las Empresas con Programas de Maquila",
  "Régimen fiscal de extranjeros que perciben ingresos en México",
  "Régimen de Asociaciones Religiosas",
];

const availableCFDI = [
  "G01 Adquisición de mercancías",
  "G02 Devoluciones, descuentos o bonificaciones",
  "G03 Gastos en general",
  "I01 Construcciones",
  "I02 Mobilario y equipo de oficina por inversiones",
  "03 Equipo de transporte",
  "I04 Equipo de cómputo y accesorios",
  "I05 Dados, troqueles, moldes, matrices y herramental",
  "I06 Comunicaciones telefónicas",
  "I08 Otra maquinaria y equipo",
  "CP01 Pagos",
  "CN01 Nómina",
  "S01 Sin Efectos Fiscales",
];

const FiscalDataForm: React.FC<IProps> = ({  }: IProps) => {
  const dispatch = useDispatch();
  const [regimens, setRegimens] = useState(availableTaxRegimens);
  const [uses, setUses] = useState(availableCFDI);

  return (
    <Fragment>
      <Row>
        <p className="add-client-title-text">
          Datos fiscales
        </p>
      </Row>
      <Row>
        <Col sm={24} lg={10}>
          <CkInput
            name={["customerReceptorName"]}
            extra="Nombre del receptor"
            inputProps={{
              name: "customerReceptorName",
              placeholder: "",
              onChange: (e: any)=>{
                dispatch(updateCustomerFormData({ customerReceptorName: e.target.value }));
              }
            }}
            style={{ paddingRight: "20px"}}
          />
        </Col>
        <Col sm={24} lg={10}>
          <CkInput
            name={["customerRFC"]}
            extra="RFC"
            inputProps={{
              name: "customerRFC",
              placeholder: "",
              onChange: (e: any)=>{
                dispatch(updateCustomerFormData({ customerRFC: e.target.value }));
              }
            }}
            style={{ paddingRight: "20px"}}
          />
        </Col>
        <Col sm={24} lg={10}>
          <CkSelect
            name={["customerTaxRegime"]}
            extra="Régimen fiscal"
            // className="form-input-text"
            inputProps={{
              // className: "input-bottom-border appo-form-item",
              placeholder: "Selecciona",
              dropdownStyle: { zIndex: 5000 },
              //onChange: (e) => getDataVehicleBrandsYears(e),
              options: regimens?.map((reg: any, index) => {
                return {
                  value: reg,
                  label: reg,
                };
              }),
              onChange: (e: any)=>{
                dispatch(updateCustomerFormData({ customerTaxRegime: e }));
              }
            }}
            style={{ paddingRight: "20px"}}
          />
        </Col>
        <Col sm={24} lg={10}>
          <CkSelect
            name={["customerCFDI"]}
            extra="Uso de CFDI"
            // className="form-input-text"
            inputProps={{
              // className: "input-bottom-border appo-form-item",
              placeholder: "Selecciona",
              dropdownStyle: { zIndex: 5000 },
              //onChange: (e) => getDataVehicleBrandsYears(e),
              options: uses?.map((use: any, index) => {
                return {
                  value: use,
                  label: use,
                };
              }),
              onChange: (e: any)=>{
                dispatch(updateCustomerFormData({ customerCFDI: e }));
              }
            }}
            style={{ paddingRight: "20px"}}
          />
        </Col>
      </Row>
    </Fragment>
  );
};

export default memo(FiscalDataForm);
