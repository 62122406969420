import React, {Fragment, memo, useEffect, useState} from "react";
import {Divider, Drawer, Form, message, Row} from "antd";
import ClientDataForm from "./ClientDataForm";
import ComplementaryClientDataForm from "./ComplementaryClientDataForm";

import "./style.css";
import FiscalDataForm from "./FiscalDataForm";
import ClientTagsForm from "./ClientTagsForm";
import ClientVehiclesForm from "./ClientVehiclesForm";
import AppointmentHistoryForm from "./AppointmentHistoryForm";
import { CkButton, CkIcon } from "../../../CkUI";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerFormData } from "../../../stores";
import {
  fetchWorkshopTags,
  resetCustomerFormData,
  resetVehicleFormData
} from "../../../stores/Slice/NewCustomerFormSlice";
import { ClientsAPI } from "../../../app/Services/axios";
import moment from "moment";

interface IProps {
  open: boolean;
  setOpen: Function;
  onClose: Function;
  currentWorkshopId: string;
  updateCustomerList: Function;
  isEditingCustomer: boolean;
  setIsEditingCustomer: Function;
  showModalMessage: Function;
}

const AddClientDrawer: React.FC<IProps> = (
  {
    open,
    setOpen,
    onClose,
    currentWorkshopId,
    updateCustomerList,
    isEditingCustomer,
    setIsEditingCustomer,
    showModalMessage
  }: IProps) => {
  const dispatch = useDispatch();
  const customerData = useSelector(getCustomerFormData);
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    //console.log('Success:', values);
  };

  const onAddClientClick = async () => {
    const dateOfBirthFormatted = customerData.customerDateOfBirth? customerData.customerDateOfBirth.slice(0, 10) : "";
    try {
      if(isEditingCustomer) {
        const editedClient = await ClientsAPI.updateClient(currentWorkshopId, customerData.customerId, { ...customerData, customerDateOfBirth: dateOfBirthFormatted });
      } else {
        const addedClient = await ClientsAPI.addClient(currentWorkshopId, {
          ...customerData,
          customerDateOfBirth: dateOfBirthFormatted
        });
      }
      updateCustomerList();
      setOpen(false);
      setIsEditingCustomer(false);
    } catch (e) {
      setOpen(false);
      setIsEditingCustomer(false);
      showModalMessage("Los datos del cliente que quieres agregar ya coinciden con uno que ya se encuentra registrado.");
    }

  };

  useEffect( () => {
    if(open) {
      dispatch(fetchWorkshopTags(currentWorkshopId));
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [open]);

  useEffect(() => {
    if(open) {
      console.log("customerDataToAdd/Edit: ", customerData);
      if(!isEditingCustomer) dispatch(resetCustomerFormData());
      dispatch(resetVehicleFormData());
    }
  }, [open]);

  useEffect(() => {
    let customerDateOfBirth: any = "";
    if(customerData.customerDateOfBirth){
      customerDateOfBirth = moment(customerData.customerDateOfBirth);
    }
    form.setFieldsValue({...customerData, customerDateOfBirth: customerDateOfBirth});
  }, [customerData]);

  return (
    <Drawer
      className="add-client-drawer"
      title={
        <Fragment>
          <p style={{ color: "#2369BD", fontSize: "24px", margin: 0 }}>
            { isEditingCustomer? "Editar cliente" : "Nuevo cliente"}
          </p>
          <Divider className="divider-title" />
        </Fragment>
      }
      placement="right"
      width="90%"
      open={open}
      closeIcon={<CkIcon name="caret-left-large" fill="" height="" width=""/> }
      onClose={() => {
        setIsEditingCustomer(false);
        onClose();
      }}
      style={{ zIndex: 2147483641 }}
    >
      <Form
        form={form}
        name="add-client-form"
        style={{ padding: "0 4% 15px 6%"}}
        onFinish={onFinish}
        initialValues={customerData}
        onValuesChange={(changedValues, allValues)=>{
          console.log("changedValues", changedValues);
          console.log("allValues", allValues);
        }}
      >
        <ClientDataForm additionalContacts={customerData?.contacts} />
        <Divider />

        <ComplementaryClientDataForm />
        <Divider />

        <FiscalDataForm />
        <Divider />

        <ClientTagsForm onCloseTag={()=> {}} currentWorkshopId={currentWorkshopId}/>
        <Divider />

        <ClientVehiclesForm vehicles={customerData?.customerVehicles} />
        <Divider />

        {/*
        <AppointmentHistoryForm appointments={customerData?.appointments} vehicles={customerData?.customerVehicles}/>
        <Divider />
        */}

        <Form.Item>
          <div style={{display: "flex", flexDirection: "column", width: "100%", alignItems: "center"}}>
            <CkButton htmlType="submit" onClick={() => onAddClientClick()}>
              {
                isEditingCustomer ? "Actualizar" : "Agregar cliente"
              }
            </CkButton>
            <CkButton
              style={{ marginTop: "10px"}}
              type="text"
              onClick={() => {
                setOpen(false);
                setIsEditingCustomer(false);
              }}
            >
              Cancelar
            </CkButton>
          </div>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default memo(AddClientDrawer);
