import React, { Fragment, memo, useState } from "react";
import { Row, Tag } from "antd";
import { CkButton } from "../../../../CkUI";

import AddNewTagForm from "./AddNewTagForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentWorkshopTags,
  getCustomerFormData,
} from "../../../../stores";
import { ClientsAPI } from "../../../../app/Services/axios";
import { addNewWorkshopTag } from "../../../../stores/Slice/NewCustomerFormSlice";

interface IProps {
  onCloseTag: Function;
  currentWorkshopId: string;
}

const ClientTagsForm: React.FC<IProps> = ({
  currentWorkshopId,
  onCloseTag,
}: IProps) => {
  const dispatch = useDispatch();
  const customerData = useSelector(getCustomerFormData);
  const [showAddTagForm, setShowAddTagForm] = useState(false);

  const displayTags = () => {
    return customerData?.customerTags.map((tag, index) => {
      return (
        <Tag
          className="tag-card"
          color="geekblue"
          key={index}
          closable
          onClose={() => onCloseTag(tag)}
        >
          {tag.tagName}
        </Tag>
      );
    });
  };

  const onCreateTagClicked = async (newTag: string, setNewTag: Function) => {
    dispatch(
      addNewWorkshopTag({ workshopId: currentWorkshopId, tagName: newTag })
    );
    setNewTag("");
  };

  return (
    <Fragment>
      <Row>
        <p className="add-client-title-text">Etiquetas</p>
      </Row>
      <Row>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            alignItems: "center",
            gap: 10,
          }}
        >
          {displayTags()}
          <CkButton
            type="text"
            icon="circle-add"
            onClick={() => setShowAddTagForm(true)}
            style={{
              display: "flex",
              flexDirection: "row",
              border: "1px solid #2369BD",
              maxWidth: "150px",
              height: "40px",
            }}
          >
            <p style={{ fontSize: "14px", fontWeight: 400, margin: 0 }}>
              Agregar etiqueta
            </p>
          </CkButton>
        </div>
      </Row>
      {showAddTagForm && (
        <AddNewTagForm
          onCreateTagClicked={onCreateTagClicked}
          tags={customerData.customerTags}
          setOpen={setShowAddTagForm}
          onSaveClicked={undefined}
        />
      )}
    </Fragment>
  );
};

export default memo(ClientTagsForm);
