import React, { Fragment, memo, useState } from "react";
import {Col, Radio, RadioChangeEvent, Row} from "antd";
import {CkDatePicker, CkInput} from "../../../../CkUI";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {updateCustomerFormData} from "../../../../stores/Slice/NewCustomerFormSlice";
import {getCustomerFormData} from "../../../../stores";

interface IProps {}

const ComplementaryClientDataForm: React.FC<IProps> = ({  }: IProps) => {
  const dispatch = useDispatch();
  //const [value, setValue] = useState("");
  const customerData = useSelector(getCustomerFormData);
  const onChangeRadioGroup = (e: RadioChangeEvent) => {
    //console.log('radio checked', e.target.value);
    //setValue(e.target.value);
    dispatch(updateCustomerFormData({ customerSex: e.target.value }))
  };
  return (
    <Fragment>
      <Row>
        <p className="add-client-title-text">
          Datos complementarios
        </p>
      </Row>
      <Row>
        <Col sm={24} lg={10}>
          <CkInput
            name={["customerZipCode"]}
            extra="Código postal"
            rules={[
              {
                required: true,
                message: "Ingresa el nombre",
              },
              {
                pattern: new RegExp(/^[a-zA-Zá-úÁ-Ú0-9\s]+$/gi),
                message: "El campo no admite caracteres especiales",
              },
            ]}
            inputProps={{
              name: "customerZipCode",
              placeholder: "Escribe el codigo postal",
              onChange: (e: any)=>{
                dispatch(updateCustomerFormData({ customerZipCode: e.target.value }));
              }
            }}
            style={{ paddingRight: "20px"}}
          />
        </Col>
        <Col sm={24} lg={10}>
          <CkDatePicker
            name="customerDateOfBirth"
            extra="Fecha de nacimiento"
            // className="form-input-text"
            inputProps={{
              inputReadOnly: true,
              placeholder: "Selecciona la fecha",
              format: ["DD-MM-YYYY", "DD-MM-YY"],
              popupStyle: {
                //zIndex: size.width > 992 ? 1110 : 2600,
              },
              onChange: (e: any)=>{
                const formattedDate = moment(e).toDate().toISOString();
                dispatch(updateCustomerFormData({ customerDateOfBirth: formattedDate }));
              },

            }}
            style={{ paddingRight: "20px"}}
          />
        </Col>
        <Col sm={24} lg={10}>
          <CkInput
            name={["customerAddress"]}
            extra="Dirección de recolección"
            inputProps={{
              name: "customerAddress",
              placeholder: "",
              onChange: (e: any)=>{
                dispatch(updateCustomerFormData({ customerAddress: e.target.value }));
              }
            }}
            style={{ paddingRight: "20px"}}
          />
        </Col>
        <Col sm={24} lg={10}>
          <CkInput
            name={["customerNotes"]}
            extra="Notas"
            inputProps={{
              name: "customerNotes",
              placeholder: "",
              onChange: (e: any)=>{
                dispatch(updateCustomerFormData({ customerNotes: e.target.value }));
              }
            }}
            style={{ paddingRight: "20px"}}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <p style={{ fontSize: 16, fontWeight: 600}}>Género</p>
        </Col>
        <Radio.Group onChange={onChangeRadioGroup} value={customerData.customerSex}>
          <Radio value={"M"}>Hombre</Radio>
          <Radio value={"F"}>Mujer</Radio>
          <Radio value={"O"}>Otro</Radio>
          <Radio value={"N"}>Prefiere no contestar</Radio>
        </Radio.Group>
      </Row>
    </Fragment>
  );
};

export default memo(ComplementaryClientDataForm);
