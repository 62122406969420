import React, { memo, useState } from "react";
import "./style.css";

interface IProps {
  tag: any;
  onSelected: Function;
}

const SelectableTag: React.FC<IProps> = ({ tag, onSelected }: IProps) => {
  const [isSelected, setIsSelected] = useState(false);
  const selectionToggled = () => {
    onSelected(tag, isSelected);
    setIsSelected(!isSelected);
  };
  return (
    <div
      style={{ alignItems: "center" }}
      className={isSelected ? "tag-item selected" : "tag-item"}
      onClick={() => selectionToggled()}
    >
      <p style={{ margin: 0 }}>{tag.tagName}</p>
    </div>
  );
};

export default memo(SelectableTag);
