import React, {Fragment, memo, useEffect} from "react";
import {Col, Divider, Form, Row, Tag} from "antd";
import {CkButton, CkInput} from "../../../../../CkUI";
import {
  addAdditionalCustomerToData,
  resetAdditionalContactFormData, updateAdditionalContactFormData,
  updateCustomerFormData
} from "../../../../../stores/Slice/NewCustomerFormSlice";
import {useDispatch, useSelector} from "react-redux";
import {getAdditionalContactFormData} from "../../../../../stores";

interface IProps {
  setOpen: Function;
  open: boolean;
  showTitle: boolean;
  backgroundColor?: string;
}

const AdditionalContactForm: React.FC<IProps> = ({ setOpen, open, showTitle, backgroundColor = "#F9F9F9" }: IProps) => {
  const dispatch = useDispatch();
  const additionalContact = useSelector(getAdditionalContactFormData)

  useEffect(() => {
    if(open) {
      dispatch(resetAdditionalContactFormData());
    }
  }, [open]);

  const onSaveClicked = () => {
    dispatch(addAdditionalCustomerToData(additionalContact));
    dispatch(resetAdditionalContactFormData());
    setOpen(false);
  }

  return (
    <div>
      {
        showTitle && (
          <Row justify="space-between">
            <p className="add-client-title-text">
              Contacto adicional
            </p>
          </Row>
        )
      }
      <div
        style={{
          backgroundColor: backgroundColor,
        }}
      >{/*} style={{ backgroundColor: "#F9F9F9"}}>*/}
        <Form
          name="additional-contact-form"
          initialValues={additionalContact}
        >
          <Row
            style={{
              padding: "25px",
            }}
          >
            <Col sm={24} lg={10}>
              <CkInput
                name={["contactName"]}
                extra="*Nombre"
                rules={[
                  {
                    required: true,
                    message: "Ingresa el nombre",
                  },
                  {
                    pattern: new RegExp(/^[a-zA-Zá-úÁ-Ú0-9\s]+$/gi),
                    message: "El campo no admite caracteres especiales",
                  },
                ]}
                inputProps={{
                  name: "customerName",
                  placeholder: "Escribe el nombre del cliente",
                  onChange: (e: any)=>{
                    dispatch(updateAdditionalContactFormData({ contactName : e.target.value }));
                  }
                }}
                style={{ paddingRight: "20px"}}
              />
            </Col>
            <Col sm={24} lg={10}>
              <CkInput
                name={["contactLastName"]}
                extra="*Apellido"
                rules={[
                  {
                    required: true,
                    message: "Ingresa el apellido",
                  },
                  {
                    pattern: new RegExp(/^[a-zA-Zá-úÁ-Ú0-9\s]+$/gi),
                    message: "El campo no admite caracteres especiales",
                  },
                ]}
                inputProps={{
                  name: "contactLastName",
                  placeholder: "Escribe el apellido del cliente",
                  onChange: (e: any)=>{
                    dispatch(updateAdditionalContactFormData({ contactLastName : e.target.value }));
                  },
                }}
                style={{ paddingRight: "20px"}}
              />
            </Col>
            <Col sm={24} lg={10}>
              <CkInput
                name={["contactTel"]}
                extra="*Teléfono celular"
                rules={[
                  {
                    required: true,
                    message: "Ingresa el teléfono del cliente",
                  },
                  {
                    pattern: new RegExp(/^\d{10}$/gi),
                    message: "El teléfono debe ser de 10 números",
                  },
                ]}
                inputProps={{
                  name: "contactTel",
                  placeholder: "Escribe el celular del cliente",
                  onChange: (e: any)=>{
                    dispatch(updateAdditionalContactFormData({ contactTel : e.target.value }));
                  },
                }}
                style={{ paddingRight: "20px"}}
              />
            </Col>
            <Col sm={24} lg={10}>
              <CkInput
                name={["contactEmail"]}
                extra="*Correo electrónico"
                rules={[
                  {
                    required: true,
                    message: "Ingresa el correo electrónico",
                  },
                  {
                    type: "email",
                    message: "Ingresa un correo electrónico",
                  },
                ]}
                inputProps={{
                  name: "contactEmail",
                  type: "email",
                  placeholder: "Escribe el correo electrónico del cliente",
                  onChange: (e: any)=>{
                    dispatch(updateAdditionalContactFormData({ contactEmail : e.target.value }));
                  },
                }}
                style={{ paddingRight: "20px"}}
              />
            </Col>
          </Row>

          <Form.Item>
            <div style={{display: "flex", flexDirection: "column", width: "100%", alignItems: "center"}}>
              <CkButton htmlType="submit" onClick={()=>onSaveClicked()}>Guardar</CkButton>
              <CkButton type="text" onClick={() => setOpen(false)}>Cancelar</CkButton>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default memo(AdditionalContactForm);
