import React, {memo, useEffect, useState} from "react";
import {Col, Form, Row, Select} from "antd";
import {CkButton, CkInput, CkInputNumber, CkSelect, CkVehicleTypeSelection} from "../../../../../CkUI";
import * as CatalogAPI from "shared/src/services/catalogsAPI";
import {useDispatch, useSelector} from "react-redux";
import {
  addCustomerVehicleToData,
  resetVehicleFormData,
  updateVehicleFormData
} from "../../../../../stores/Slice/NewCustomerFormSlice";
import {fetchVehicleModels} from "shared/src/services/catalogsAPI";
import {getCustomerFormData, getVehicleFormData} from "../../../../../stores";
import moment from "moment";
import vehicle from "../../../../../CkUI/components/atoms/CkIcon/vehicle";

interface IProps {
  setOpen: Function;
  open: boolean;
}

const fuelOptions = [
  {
    value: "GASOLINA",
    label: "GASOLINA",
  },
  {
    value: "DIESEL",
    label: "DIESEL",
  },
  {
    value: "ELECTRICO",
    label: "ELECTRICO",
  },
  {
    value: "HIBRIDO",
    label: "HIBRIDO",
  },
];

export interface VehicleCatalog {
  code: string;
  desc: string;
  modelYears?: number[];
  brandCode?: string;
}

export interface VehicleMainPurpose {
  vehicleMainPurposeCode: string;
  vehicleMainPurposeDesc: string;
}

const NewVehicleForm: React.FC<IProps> = ({ open, setOpen }: IProps) => {
  const dispatch = useDispatch();
  const vehicleFormData = useSelector(getVehicleFormData);
  const [isCustomBrand, setIsCustomBrand] = React.useState<boolean>(false);
  const [customBrand, setCustomBrand] = React.useState<string>("");
  const [isCustomModel, setIsCustomModel] = React.useState<boolean>(false);
  const [customModel, setCustomModel] = React.useState<string>("");
  const [typeCode, setTypeCode] = useState("");
  const [purposes, setPurposes] = useState<VehicleMainPurpose[]>([]);
  const [vehicleCategorySelected, setVehicleCategorySelected] = useState<string>("");
  const [vehicleTypes, setVehicleTypes] = useState<VehicleCatalog[]>([]);
  const [vehicleBrands, setVehicleBrands] = useState<VehicleCatalog[]>([]);
  const [vehicleYearsN, setVehicleYearsN] = useState<VehicleCatalog[]>([]);
  const [vehicleModels, setVehicleModels] = useState<VehicleCatalog[]>([]);
  const [vehicleFuelType, setVehicleFuelType] = useState<VehicleCatalog[]>([]);

  useEffect(() => {
    console.log("vehicleBrands????: ", vehicleBrands);
  }, [vehicleBrands]);

  const validateKm = () => {
    const value = vehicleFormData.endConsumerVehicleKm;
    const parsedValue = isNaN(parseInt(value)) ? undefined : parseInt(value);
    if (parsedValue === undefined) {
      //form.resetFields(["km"]);
      dispatch(updateVehicleFormData({ endConsumerVehicleKm: ""}))
    } else {
      //form.setFieldsValue({ km: parsedValue });
      dispatch(updateVehicleFormData({ endConsumerVehicleKm: parsedValue }))
    }
    //validateForm();
  };

  const getPurposes = async () => {
    CatalogAPI.fetchVehicleMainPurposes().then((values) => {
      setPurposes(values.data);
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      let result = await CatalogAPI.fetchVehicleTypes();
      if (result?.status === 200) {
        const mappedResult = result.data;
        setVehicleTypes(mappedResult);
      }
    };
    if(open) {
      fetchData();
      getPurposes();
    }
  }, [open]);

  const validateYearCode = () => {
    const value = vehicleFormData.endConsumerVehicleYear;
    const parsedValue = isNaN(value) ? undefined : value;
    if (parsedValue === undefined) {
      //form.resetFields(["yearCode"]);
      dispatch(updateVehicleFormData({ endConsumerVehicleYear: "" }))
    } else {
      //form.setFieldsValue({ yearCode: parsedValue });
      dispatch(updateVehicleFormData({ endConsumerVehicleYear: parsedValue }))
    }
    //validateForm();
  };
  const getVehicleFuelType = (selected: string) => {
    const brandCode = vehicleFormData.endConsumerVehicleBrand;
    const yearCode = vehicleFormData.endConsumerVehicleYear;

    CatalogAPI.fetchVehicleFuelTypes(
      vehicleCategorySelected,
      selected,
      brandCode,
      yearCode.toString()
    ).then((values) => {
      setVehicleFuelType(values.data);
    });
  };
  const getVehicleModels = async (selected: string) => {
    //form.resetFields(["modelCode", "fuelTypeCode"]);
    //const brandCode = form.getFieldValue("brandCode");
    const brandCode = vehicleFormData.endConsumerVehicleBrand;

    CatalogAPI.fetchVehicleModels(
      vehicleCategorySelected,
      selected,
      brandCode
    ).then((values) => {
      const otherModel: VehicleCatalog = {
        code: "custom_model",
        desc: "Otro modelo",
      };
      setVehicleModels([
        ...values.data,
        ...(values.data.some((model) => model.code === "custom_model")
          ? []
          : [otherModel]),
      ]);
      setVehicleFuelType([]);
    });
  };

  const getVehicleBrandsYears = (selected: string) => {
    if (selected.startsWith("custom_brand")) {
      setIsCustomBrand(true);
      setIsCustomModel(false);
      setCustomModel("");
    } else setIsCustomBrand(false);

    CatalogAPI.fetchVehicleBrandYears(vehicleCategorySelected, selected).then(
      (values) => {
        setVehicleYearsN(
          values.data.sort((a, b) => parseInt(b.code) - parseInt(a.code))
        );
        setVehicleModels([]);
        setVehicleFuelType([]);
      }
    );/*
    form.resetFields([["vehicle", "yearCode"]]);
    form.resetFields([["vehicle", "modelCode"]]);
    form.resetFields([["vehicle", "fuelTypeCode"]]);
    form.resetFields([["vehicle", "displacementCode"]]);
    form.resetFields([["vehicle", "equipmentCode"]]);*/
    setCustomBrand("");
  };

  const getVehicleBrands = (selected: string) => {
    const otherBrand: any = { code: "custom_brand", desc: "Otra marca" };
    CatalogAPI.fetchVehicleBrands(selected).then((values) => {
      let allBrands = values.data;
      if (allBrands.filter((x) => x.code === "custom_brand")?.length === 0)
        allBrands.push(otherBrand);
      setVehicleBrands(allBrands);
      setVehicleYearsN([]);
      setVehicleModels([]);
      setVehicleFuelType([]);
    });

    setCustomBrand("");
  };
  const TypeOfVehicle = () => (
    <div className="car-type-container">
      {vehicleTypes.length > 0 && (
        <CkVehicleTypeSelection
          size="full"
          name="typeCode"
          label="*Tipo de vehículo"
          vehicleButtonProps={{
            selected: vehicleCategorySelected === vehicleTypes[0]?.code,
            id: "btn-selection-v",
            onClick: (e) => {
              //dispatch(updateVehicleFormData({ typeCode: ""}))
             /*
              vehicleForm.setFields([
                {
                  name: "typeCode",
                  value: vehicleTypes[0].code,
                },
              ]);*/
              getVehicleBrands(vehicleTypes[0].code);
              setVehicleCategorySelected(vehicleTypes[0].code);
              dispatch(updateVehicleFormData({ endConsumerVehicleType: vehicleTypes[0].code }))
            },
          }}
          truckButtonProps={{
            selected: vehicleCategorySelected === vehicleTypes[1]?.code,
            id: "",
            onClick: (e) => {
              setVehicleCategorySelected(vehicleTypes[1].code);
              getVehicleBrands(vehicleTypes[1].code);
              dispatch(updateVehicleFormData({ endConsumerVehicleType: vehicleTypes[1].code }))
            },
          }}
          motocycleButtonProps={{
            selected: vehicleCategorySelected === vehicleTypes[2]?.code,
            id: "",
            onClick: (e) => {
              getVehicleBrands(vehicleTypes[2].code);
              setVehicleCategorySelected(vehicleTypes[2].code);
              dispatch(updateVehicleFormData({ endConsumerVehicleType: vehicleTypes[2].code }))
            },
          }}
        />
      )}
    </div>
  );

  const knowCustomModel = (selected: any)=> {
    if (selected == "custom_model") {
      setIsCustomModel(true);
    } else {
      setIsCustomModel(false);
    }
    getVehicleFuelType(selected);
  }

  const hasMinData = () => {
    return !(vehicleFormData.endConsumerVehicleType &&
      vehicleFormData.endConsumerVehicleBrand &&
      vehicleFormData.endConsumerVehicleModel &&
      vehicleFormData.endConsumerVehicleYear > 0);
  }

  return (
    <div style={{ backgroundColor: "#F9F9F9", padding: "25px"}}>
      <Form
        name="new-vehicle-form"
      >
        <p style={{ fontSize: "20px", color: "#001450"}}>
          Agregar nuevo vehículo
        </p>

        <Row justify="center">
          <Col span={20}>
            <TypeOfVehicle />
          </Col>
        </Row>

        <Row justify="center">
          <Col span={customBrand ? 10 : 20}>
            <CkSelect
              label="*Marca"
              style={{
                width: "100%",
                background: "white",
              }}
              rules={[
                {
                  required: true,
                  message: "Este campo es obligatorio",
                },
              ]}
              inputProps={{
                disabled: vehicleBrands.length === 0,
                placeholder: "Marca",
                dropdownStyle: { zIndex: 5000 },
                onChange: (e) => {
                  getVehicleBrandsYears(e);
                  dispatch(updateVehicleFormData({ endConsumerVehicleBrand: e }))
                },
                options: vehicleBrands?.map((brand: any, index) => {
                  return {
                    value: brand.code,
                    label: brand.code === "custom_brand"
                      ? "Otra marca"
                      : brand.desc,
                  };
                }),
              }}
            />
          </Col>
          {isCustomBrand && (
            <Col span={10}>
              <CkInput
                label="Otra marca"
                name="customBrandCode"
                rules={[
                  {
                    required: true,
                    message: "Este campo es obligatorio",
                  },
                ]}
                inputProps={{
                  disabled:
                    vehicleCategorySelected.length === 0,
                  placeholder: "Ingresa marca",
                  onChange: (e) => { setCustomBrand(e.target.value)},
                }}
              />
            </Col>
          )}
        </Row>

        <Row justify="center">
          <Col span={20}>
            {!isCustomBrand && (
              <CkSelect
                variant="default"
                label="*Año"
                rules={[
                  {
                    required: true,
                    message: "Este campo es obligatorio",
                  },
                ]}
                inputProps={{
                  showSearch: true,
                  disabled: vehicleYearsN.length === 0,
                  placeholder: "Selecciona año",
                  onChange: (selected) => {
                    getVehicleModels(selected);
                    dispatch(updateVehicleFormData({ endConsumerVehicleYear: selected }))
                  },
                  onClick: (e) => {
                    //gtmSetId(e.currentTarget)
                  },
                  options: vehicleYearsN
                    ?.slice()
                    .sort((a, b) => parseInt(b.desc) - parseInt(a.desc))
                    .map((year: any, index) => {
                      return {
                        label: year.desc,
                        value: year.code,
                      };
                    }),
                }}
              />
            )}
            {isCustomBrand && (
              <CkInputNumber
                className="--alter"
                label="Año"
                name="yearCode"
                rules={[
                  {
                    required: true,
                    message: "Este campo es obligatorio",
                  },
                ]}
                inputProps={{
                  disabled:
                    vehicleCategorySelected.length === 0,
                  min: 1960,
                  max: moment().year() + 1,
                  controls: false,
                  onChange: validateYearCode,
                  onBlur: validateYearCode,
                }}
              />
            )}
          </Col>
        </Row>

        <Row justify="center">
          <Col span={isCustomBrand ? 20 : isCustomModel ? 10 : 20}>
            {!customBrand && (
              <CkSelect
                className="--alter"
                variant="default"
                label="*Modelo"
                rules={[
                  {
                    required: true,
                    message: "Este campo es obligatorio",
                  },
                ]}
                inputProps={{
                  showSearch: true,
                  disabled: vehicleModels.length === 0,
                  placeholder: "Selecciona modelo",
                  onChange: (selected) => {
                    dispatch(updateVehicleFormData({ endConsumerVehicleModel : selected }))
                    knowCustomModel(selected)
                  },
                  onClick: (e) => {
                    //gtmSetId(e.currentTarget);
                  },
                  options: vehicleModels.map((model: any, index) => {
                    return {
                      label:
                        model.code === "custom_model"
                          ? "Otro modelo"
                          : model.desc,
                      value: model.code,
                    };
                  }),
                }}
              />
            )}
            {isCustomBrand && (
              <CkInput
                className="--alter"
                variant="default"
                label="Modelo"
                name="modelCode"
                rules={[
                  {
                    required: true,
                    message: "Este campo es obligatorio",
                  },
                ]}
                inputProps={{
                  placeholder: "Ingresa modelo",
                  onChange: (e) => {
                    //validateForm
                    dispatch(updateVehicleFormData({ endConsumerVehicleModelCode: e.target.value }))
                  },
                }}
              />
            )}
          </Col>
          {isCustomBrand
            ? null
            : isCustomModel && (
            <Col span={10}>
              <CkInput
                className="--alter"
                variant="default"
                label="Otro modelo"
                name="customModelCode"
                rules={[
                  {
                    required: true,
                    message: "Este campo es obligatorio",
                  },
                ]}
                inputProps={{
                  disabled: vehicleModels.length === 0,
                  placeholder: "Ingresa modelo",
                  onChange: (e) => {
                    //validateForm
                  },
                }}
              />
            </Col>
          )}
        </Row>

        <Row justify="center">
          <Col span={20}>
            <CkSelect
              className="--alter"
              label="Combustible"
              name="fuelTypeCode"
              inputProps={{
                id: "GRGADD011",
                placeholder: "Selecciona el tipo de combustible",
                disabled:
                  (isCustomBrand || isCustomModel) === false &&
                  vehicleFuelType.length === 0,
                onChange: (e) => {
                  //validateForm
                  dispatch(updateVehicleFormData({ endConsumerVehicleFuelType:  e}))
                },
                onClick: (e) => {
                  //gtmSetId(e.currentTarget)
                },
                options: [
                  ...(((isCustomBrand || isCustomModel) && [...fuelOptions]) ||
                    ((isCustomBrand || isCustomModel) === false &&
                      vehicleFuelType.map((fuel: any, index) => {
                        return {
                          value: fuel.code,
                          label: fuel.desc,
                        };
                      }))),
                ],
              }}
            />
          </Col>
        </Row>

        <Row justify="center">
          {/* Placa */}
          <Col span={10} style={{ paddingRight: "25px"}}>
            <CkInput
              className="--alter"
              label="Placas"
              name="licensePlate"
              rules={[
                {
                  pattern: /^(?:[0-9]+[a-z]|[a-z]+[0-9])[a-z0-9]*$/i,
                  message:
                    "Verifique que los datos de su placa sean correctos, este campo es obligatorio para agendar su cita",
                },
                {
                  min: 5,
                  message: "Debe contener mínimo 5 caracteres",
                },
                {
                  max: 7,
                  message: "Debe contener máximo 7 caracteres",
                },
              ]}
              inputProps={{
                id: "GRGADD014",
                placeholder: "Anota las placas",
                onChange: (e) => {
                  //validateForm
                  dispatch(updateVehicleFormData({ endConsumerVehicleLicensePlate: e.target.value }))
                },
                onClick: (e) => {
                  //gtmSetId(e.currentTarget)
                },
              }}
            />
          </Col>
          {/* Placa */}
          {/* Numero de Serie */}
          <Col span={10}>
            <CkInput
              className="--alter"
              label="Número de serie"
              inputProps={{
                placeholder: "Anota el # de serie",
                onChange: (e) => {
                  //validateForm
                  dispatch(updateVehicleFormData({ endConsumerVehicleSeriesNo: e.target.value }))
                },
                onClick: (e) => {
                  //gtmSetId(e.currentTarget)
                },
              }}
            />
          </Col>
          {/* Numero de Serie */}
        </Row>

        <Row justify="center">
          <Col span={20}>
            <CkSelect
              className="--alter"
              label="Uso principal del vehículo"
              name="mainPurposeCode"
              inputProps={{
                id: "GRGADD017",
                showSearch: true,
                placeholder: "Selecciona el uso principal",
                onChange: (e) => {
                  //validateForm
                  dispatch(updateVehicleFormData( { endConsumerVehicleMainPurposeCode: e }))
                },
                onClick: (e) => {
                  //gtmSetId(e.currentTarget)
                },
                options: purposes.map(
                  (use: VehicleMainPurpose, index: number) => {
                    return {
                      value: use.vehicleMainPurposeCode,
                      label: use.vehicleMainPurposeDesc,
                    };
                  }
                ),
              }}
            />
          </Col>
        </Row>

        <Row justify="center">
          <Col span={20}>
            <CkInputNumber
              className="--alter"
              label="Kilometraje"
              name="km"
              rules={[
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (value > 5000000) {
                      return Promise.reject(
                        new Error(
                          "El kilometraje no puede ser mayor a 5 000 000km"
                        )
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
              inputProps={{
                placeholder: "Anota el kilometraje del vehículo",
                min: 1,
                max: 5000000,
                controls: false,
                onChange: (e) => {
                  //validateForm
                  dispatch(updateVehicleFormData({ endConsumerVehicleKm: e}))
                },
                onBlur: (e) => {
                  //validateKm
                },
                onClick: (e) => {
                  //gtmSetId(e.currentTarget)
                },
              }}
            />
          </Col>

        </Row>

        <Form.Item>
          <div style={{display: "flex", flexDirection: "column", width: "100%", alignItems: "center"}}>
            <CkButton
              disabled={hasMinData()}
              onClick={()=> {
                dispatch(addCustomerVehicleToData({...vehicleFormData}));
                setOpen(false);
              }}
            >
              Guardar
            </CkButton>
            <CkButton
              type="text"
              onClick={() => {
                setOpen(false);
                dispatch(resetVehicleFormData());
              }}
            >
              Cancelar
            </CkButton>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default memo(NewVehicleForm);
